import React from "react";
import PropTypes from "prop-types";

import { Table, TableHead, TableRow, TableCell } from "../ui/DivTable";
import { noop, get } from "../../utils/lodash";

const doFormatYesNo = (r) => (r ? "yes" : "no");

function transformCell(cell) {
  if (typeof cell === "boolean") {
    return doFormatYesNo(cell);
  }
  if (Array.isArray(cell)) {
    return cell.join(", ");
  }
  if (typeof cell === "string" && cell.startsWith("{")) {
    try {
      const r = JSON.parse(cell);
      if ("downloadURL" in r && "fileName" in r) {
        return (
          <>
            <a target="_blank" href={r.downloadURL} rel="noreferrer">
              <div>
                {"contentType" in r && r.contentType.startsWith("image/") && (
                  <img target="_blank" src={r.downloadURL} width={200} alt="" />
                )}
              </div>
              {r.fileName}
            </a>
          </>
        );
      }
    } catch (e) {
      // e
    }
  }
  return cell;
}

const RenderRowData = React.memo(
  ({ rowData: data, colDefns, udButtons, passConfig = () => {} }) => {
    return (
      <TableRow>
        {colDefns.map((colD) => {
          const [colN, col] = colD;
          let innerValue = "";
          if (typeof col === "string") {
            innerValue = get(data, col, "");
          } else if (typeof col === "function") {
            innerValue = col(data, passConfig);
          } else if (Array.isArray(col)) {
            innerValue = col.map((c) => get(data, c, "")).join(" ");
          }
          return <TableCell key={colN}>{transformCell(innerValue)}</TableCell>;
        })}
        {!!udButtons && <TableCell>{udButtons(data)}</TableCell>}
      </TableRow>
    );
  }
);

RenderRowData.propTypes = {
  colDefns: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
  ).isRequired,
  rowData: PropTypes.shape({}).isRequired,
  udButtons: PropTypes.func,
  passConfig: PropTypes.func,
};
RenderRowData.defaultProps = {
  udButtons: null,
  passConfig: noop,
};

const NonMemoRenderTableData1 = ({
  showHeader,
  colDefns,
  rowData,
  udButtons,
  passConfig,
}) => {
  return (
    <div>
      <Table>
        {showHeader && (
          <>
            <TableRow>
              {colDefns.map(([colLabel]) => (
                <TableHead key={`h${colLabel}`}>{colLabel}</TableHead>
              ))}
              {!!udButtons && <TableHead />}
            </TableRow>
          </>
        )}
        <>
          {rowData.map((row) => (
            <RenderRowData
              key={row.id}
              rowData={row}
              colDefns={colDefns}
              udButtons={udButtons}
              passConfig={passConfig}
            />
          ))}
        </>
      </Table>
    </div>
  );
};

NonMemoRenderTableData1.propTypes = {
  showHeader: PropTypes.bool,
  colDefns: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
  ).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  udButtons: PropTypes.func,
  passConfig: PropTypes.func,
};

NonMemoRenderTableData1.defaultProps = {
  showHeader: false,
  udButtons: null,
  passConfig: noop,
};

const RenderTableData = React.memo(NonMemoRenderTableData1);

export default RenderTableData;
