/** @jsx jsx */

import { useQueryParam, StringParam } from "use-query-params";
import { useCallback, useEffect, useState } from "react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { containerStyles } from "sites-common/utils/fabricStyles";
import { useFirestore } from "react-redux-firebase";
import { jsx, Text, Spinner } from "theme-ui";
import { formatSys2OdooDate } from "sites-common/utils/nextMonth";
import DLWLayout from "../../layouts/default";
import SearchAbhyasis from "../../components/SearchAbhyasis";

import { base10 } from "../../lib/base";

const d1 = new Date();
const today0 = formatSys2OdooDate(d1);

// const code = "DLQPWQEBUGKU";
function validateQR(c) {
  try {
    if (!c) {
      return { error: "Invalid url/code" };
    }
    const d = base10(c);
    const exp = d % 100000000;
    const id = Math.floor(d / 100000000);
    if (!id || id < 1) {
      return { error: `Invalid Code` };
    }
    if (exp < 20200000) {
      return { error: "Invalid Code" };
    }
    const e = `${exp}`;
    const expiry = `${e.substring(0, 4)}-${e.substring(4, 6)}-${e.substring(
      6,
      8
    )}`;
    if (expiry < today0) {
      return { error: "Link Expired" };
    }

    return { id };
  } catch (e1) {
    return { error: "Invalid Code" };
  }
}

const QR = () => {
  const firestore = useFirestore();

  const [code] = useQueryParam("code", StringParam);

  const [code1, setCode1] = useState(code || "");
  const [code2, setCode2] = useState("");

  const { error, id: pid } = validateQR(code1);
  const [e, setE] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code2.length === 4) {
      setLoading(true);
      firestore
        .get({ collection: "TrainerQrCodes", doc: code2 })
        .then((doc) => {
          if (doc.exists) {
            const d = doc.data();
            const { code: c1 } = d;
            setCode1(c1);
            setLoading(false);
            setE("");
          } else {
            setE("Invalid or Expired Secret Code");
            setLoading(false);
          }
        });
    }
  }, [code2, setE, setCode1, setLoading, firestore]);

  const setCode2x = useCallback(
    (c) => {
      if (c.length < 5) {
        setCode2(c.toUpperCase());
      }
    },
    [setCode2]
  );

  return (
    <DLWLayout>
      <div sx={{ textAlign: "center" }}>
        <div sx={{ mb: 2 }}>
          <Text variant="header">Search Participants using Secret Code</Text>
        </div>

        <div sx={{ pb: 3 }}>
          <div style={containerStyles.flexcenter}>
            {!code1 && (
              <DynamicField
                sx={{ maxWidth: "200px" }}
                name="code2"
                label="Secret Code"
                onChange={setCode2x}
                value={code ? code1 : code2}
              />
            )}
            {/* {!!code1 && <div>{code2}</div>} */}
          </div>
          {loading && <Spinner />}
          {error && code1.length > 6 && (
            <div>
              <small sx={{ color: "red" }}>{error}</small>
            </div>
          )}
          {e && (
            <div>
              <small sx={{ color: "red" }}>{e}</small>
            </div>
          )}
        </div>

        {/* {!!code && error && <Alert sx={{ m: 3 }}>{error}</Alert>} */}
        {pid && (
          <div key={pid}>
            <SearchAbhyasis
              title=""
              filters={["ref", "city__iexact", "email", "mobile"]}
              defaultPageSize={200}
            />
          </div>
        )}
      </div>
    </DLWLayout>
  );
};
export default QR;
